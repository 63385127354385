input {
  color: #404040 !important;
}

.progress {
  height: 8px;
}

.icon-shape {
  min-width: 65px;
}

.tooltip-inner {
  color: white;
  background-color: black;
  min-width: 250px;
  padding: 5px;
}

.tooltip-arrow::before {
  border-top-color: black !important;
}

.form-control.is-invalid {
  border-color: #d1d7e0;
}

.holdings-table-action-btn {
  color: white;
  height: 30px;
  padding: 2px 4px 2px 4px;
}

.holdings-table-action-btn:hover {
  color: white;
}

.btn-edit {
  background-color: #6d6d6d;
  border-color: #636363;
}

.btn-delete {
  background-color: #bf1e1e;
  border-color: #9c0505;
}

.bg-blue-alt {
  background-color: #c3d7fc;
}

.card-header {
  padding: 1rem 1.5rem;
}

.card-header-chart {
  border-bottom: none;
}

.map-selected {
  fill: #262b41;
}

.map-unselected {
  fill: #bbc0da;
}

.ct-area {
  fill-opacity: 0.9;
}

.ct-legend {
  z-index: 10;
  list-style: none;
  text-align: center;
}

.ct-legend li {
  text-align: left;
  position: relative;
  padding-left: 23px;
  margin-bottom: 3px;
}

.ct-legend li:before {
  width: 17px;
  height: 17px;
  position: absolute;
  left: 0;
  top: 3px;
  content: '';
  border: 4px solid transparent;
  border-radius: 2px;
}

.ct-legend.ct-legend-inside {
  padding-top: 10px;
  left: 180px;
  position: absolute;
  max-width: 160px;
}

.ct-legend li.inactive:before {
  background: transparent;
}

.ct-legend .ct-series-0:before {
  background-color: #0948B3;
  border-color: #0948B3;
}

.ct-legend .ct-series-1:before {
  background-color: #F8BD7A;
  border-color: #F8BD7A;
}

.ct-legend .ct-series-2:before {
  background-color: #1B998B;
  border-color: #1B998B;
}

.ct-legend .ct-series-3:before {
  background-color: #404E7C;
  border-color: #404E7C;
}

.align-items-center svg {
  display: block;
  margin: auto;
}

.market-chart svg .ct-series-a path {
  stroke: #6b6b6b;
}

.market-chart svg .ct-series-b path {
  stroke: #b4ed29;
}

.market-chart svg .ct-series-c path {
  stroke: #c23161;
}

.market-chart svg .ct-series-d path {
  stroke: #ae8c1d;
}

.market-chart svg .ct-series-e path {
  stroke: #23d2d4;
}

.market-chart svg .ct-series-f path {
  stroke: #fd7f23;
}

.market-chart svg .ct-series-g path {
  stroke: #9751a1;
}

.market-chart svg .ct-series-h path {
  stroke: #8082cb;
}
